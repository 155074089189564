import { Component, OnInit, Input, ViewContainerRef, ViewChild, AfterViewInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { CustomerService } from 'app/providers/customer.service';
import { Observable } from 'rxjs';
import { ShippingPointsService } from '../../../providers/shipping-point.service';

@Component({
	selector: 'app-dp-autocomplete',
	templateUrl: './dp-autocomplete.component.html',
	styles: []
})
export class DPAutocompleteComponent implements OnInit {

	private _changesTimer = null;

	private _searchTerm = '';

	@Input() customer = '';

	@Input() in = [];
	
	filteredDpList: Observable<any[]>;

	@ViewChild('dpAutocomplete') localRef;

	public get searchTerm(): string {
		return this._searchTerm;
	}

	@Input()
	public set searchTerm(value: string) {
		this._searchTerm = value;

		if (this._value === undefined)
			this._value = value;
		
		if (value !== this._value)
			this.value.emit(null);
		else
			this.value.emit(this._value);

		this.notifySearchTermChanged();
	}

	@Output() ref: EventEmitter<any>;

	@Output() value: EventEmitter<any>;

	@Output() optionSelected: EventEmitter<any>;

	@ViewChild('template') template;

	private _value: any;
	
	constructor(
		private _dpService: ShippingPointsService,
		public changeDetection: ChangeDetectorRef,
	) { 
		this.ref = new EventEmitter();
		this.value = new EventEmitter();
		this.optionSelected = new EventEmitter();
	}

	ngOnInit() {
		this.ref.emit(this.localRef);
	}

	trackByFn(index, item) {
		if (!item) return null;
		return item.Codigo || item;
	}

	notifySearchTermChanged() {
		if (this._changesTimer) {
            window.clearTimeout(this._changesTimer);
        }

        this._changesTimer = window.setTimeout(() => {
			this._filter();
			this.changeDetection.detectChanges();
        }, 222);
	}

	optionSelectedEvent(opt) {
		setTimeout(() => {
			this._value = opt.option.value;
			this.value.emit(this._value);
			this.optionSelected.emit(opt);
		}, 111);
	}

	private _filter() {
		if (this._searchTerm) {
			if (this.customer) 
			{
				if (this.customer.toString().indexOf('-'))
					this.customer = this.customer.toString().split(' - ')[0];
					
				this.filteredDpList = this._dpService.find({ param: `bycustomer/light/${this.customer}`, search: this._searchTerm });
			}
			else {
				this.filteredDpList = new Observable<any[]>();
			}
		}
	}
}
