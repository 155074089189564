import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { DPAutocompleteComponent } from './dp-autocomplete.component';

@NgModule({
    declarations: [DPAutocompleteComponent],
    imports: [ 
        CommonModule,
        MatAutocompleteModule,
        ScrollDispatchModule
    ],
    exports: [DPAutocompleteComponent],
    providers: [],
})
export class DPAutocompleteModule {}